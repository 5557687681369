import { useState, useEffect } from "react"

// Tracking
import analytics from "@mobi/libraries/analytics"

const useDeniedProposal = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    analytics.track("pageLoad", {})
    setIsLoading(false)
  }, [])

  return {
    isLoading,
  }
}

export default useDeniedProposal
