export const marketplaceCardItems = [
  {
    name: "open-account",
    url: "https://www.itau.com.br/contas/conta-corrente/?utm_source=st&utm_medium=nd&utm_campaign=st-conv-nd-st-nd-all&utm_content=nd-nd-nd-iacnta-nd-pfaqu-MT00001342-rvproduto&utm_term=icarros-x-status-marketplace&utm_sou=icarros&utm_med=x&utm_camp=status&utm_cont=marketplace&utm_ter=53",
    title: "abertura de contas",
    paragraph: "abra sua conta e aproveite os benefícios do banco Itaú",
    button: "confira",
  },
  {
    name: "credit-card",
    url: "https://cartoes.itau.com.br/formulario/?tracker=0&step=0&card=123&utm_sou=icarros&utm_med=cross-sell&utm_camp=marketplace_ls",
    title: "cartão de crédito ideal",
    paragraph: "selecione o cartão que dá match com você",
    button: "confira",
  },
  {
    name: "tag",
    url: "https://tag.itau.com.br/rd_xVRY",
    title: "tag Itaú",
    paragraph: "benefício exclusivo para clientes com cartão Itaú",
    button: "confira",
  },
]
