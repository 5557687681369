import { useEffect } from "react"

// Components
import Layout from "components/Layout"
import { Text } from "@mobi/ds"
import Header from "components/Header"
import Footer from "components/Footer"
import Loader from "components/Loader"
import If from "@mobi/components/If"
import Marketplace from "components/Marketplace"
import { Likert } from "components/Likert"
import { BackButton } from "components/BackButton"

// Hooks
import useUnavailableProposal from "./useUnavailableProposal"

// Data
import { marketplaceCardItems } from "./dataContent"

// Style
import "./index.scss"

// Illustration
import SystemFailIllutstration from "images/system-fail-illustration.svg"

// Utils
import { navigateWithQueryString } from "utils/browser"

const UnavailableProposal = () => {
  const { isLoading } = useUnavailableProposal()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout className={`-form -unavailable`}>
      <div className="unavailable-proposal">
        <Header />
        <If
          condition={isLoading}
          renderIf={<Loader />}
          renderElse={
            <>
              <div className="unavailable-proposal__container">
                <BackButton onClick={() => navigateWithQueryString("/")} />
                <div className="unavailable-proposal__illustration">
                  <SystemFailIllutstration />
                </div>
                <div className="unavailable-proposal__title-box">
                  <Text
                    className="unavailable-proposal__title"
                    as="h2"
                    size="xxl"
                    weight="800"
                  >
                    desculpe, neste momento não conseguimos gerar uma simulação
                  </Text>
                  <Text size="md">
                    Fique tranquilo(a)! Usando as informações fornecidas vamos
                    tentar fazer a simulação novamente e enviaremos um e-mail ;)
                  </Text>
                </div>
                <div className="unavailable-proposal__likert">
                  <Likert />
                </div>
                <div className="unavailable-proposal__marketplace">
                  <Marketplace
                    cardItems={marketplaceCardItems}
                    currentStep="unavailable-proposal"
                  />
                </div>
                <br />
                <br />
              </div>

              <Footer />
            </>
          }
        />
      </div>
    </Layout>
  )
}

export default UnavailableProposal
